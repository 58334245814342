const domainName = window.location.hostname;
console.log('domain name', domainName);
const localShortName = 'local-hoyp5c87we';
const devShortName = 'https-dev-beautifullifejz-com';
const prodShortName = 'beautifullifejz';

let disqusShortName;

switch(domainName){
  case 'localhost':
    disqusShortName = localShortName;
    break;
  case 'dev.beautifullifejz.com' :
    disqusShortName = devShortName;
    break;
  case 'www.beautifullifejz.com' :
    disqusShortName = prodShortName;
    break;
  case 'beautifullifejz.com' :
    disqusShortName = prodShortName;
    break;
}


export {disqusShortName};