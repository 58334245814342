import React from "react";
import "./Marma.css";

const Marma = () => <div>
  <div className="marma">

    <p className="marma-intro">
      <b>Marma Therapy</b> is an Ayurvedic treatment known to be one of the most direct and deeply relaxing ways of harmonizing an individual's built-in energetic network to restore health and vitality—the science of Marma (Marma Vidya) is the grandfather of acupressure and acupuncture.
    </p>
    <p>
      <b>Marma Therapy</b> works with vital energetic points (Marma) within the body located at the junction of muscles, ligaments, bones, joints, tendons, and nerves. These Marma points link to the subtle channels called nadis (similar to meridians in Chinese medicine). And just as passageways within the physical body become blocked by toxins, so does the energetic body—even the tiniest of mental stresses clouds and obstructs the subtle Marma points, reducing the flow of energy. In fact, <b>any sore point on the surface of the body is a blocked Marma.</b>
    </p>
    <p>
      Through the gentle pressure or stimulation of these vital points through Marma Therapy, blocks are released which allows energy, or prana, to flow to the connecting organs or tissues, giving them a new lease of life. Marma Therapy is beneficial on many levels and brings deep relaxation and rejuvenation.
    </p>
    <p>
      <center><b>Benefits of Marma Therapy</b></center>
    </p>
    <p>
      The practice of Marma benefits the body, mind, and spirit. On the bodily level, it brings about hydration, nourishment, hormonal balance, provides pain relief, balances the Ayurvedic body types (doshas), revitalizes organs, balances digestion and elimination, relaxes muscles, and is generally cleansing.
    </p>
    <p>
      On the mind level, Marma strengthens and balances your inner nature, helps the mind be more in the present moment, restores clarity, improves perception, understanding, and communication. Marma clears emotions, releases stress, and cultivates a deep state of relaxation.
    </p>
    <div className="images">
    </div>
  </div>
</div>;

export default Marma;