import React from "react";
import "./Slogan.css";

const Slogan = () =>            (
  <div className={"slogan"}>
    <div className={"healing"}>
      Healing Your Body and Heart
    </div>
    <div className={"beautiful-life"}>
      Beautiful Life
    </div>
  </div>);

export default Slogan;