import {Collapse, Container, Navbar, NavbarNav, NavbarToggler, NavItem, NavLink} from "mdbreact";
import React, {Component} from "react";
import HeaderCollapse from "./HeaderCollapse";
import "./Header.css";

export default class Header extends Component {

    render(){
        const navStyle = { marginTop: "-1.5rem" };

        return (<header className="App-header">
            <h1 className="App-title">Jenny's Mind-Body Energy Healing</h1>
            <Navbar
                color="primary-color"
                style={navStyle}
                dark
                expand="md"
                scrolling
                transparent
            >
                <Container>
                    <HeaderCollapse/>
                </Container>
            </Navbar>

        </header>);
    }
}
