import React from 'react';
import Amplify from 'aws-amplify';

import awsconfig from '../aws-exports';
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";
import {Authenticator} from 'aws-amplify-react';

Amplify.configure(awsconfig);

const LoginPage = props =>{
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  const {history} = props;


  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
    });
  }, []);

  if(authState === AuthState.SignedIn){
    history.push('/schedule');
    return null;
  }else return (
    <Authenticator />
  );
};

export default LoginPage