/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:fe10a492-b304-4571-9213-2ad5f2046568",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_MdzHVy93G",
    "aws_user_pools_web_client_id": "5galoglf4qu471pb9k31it516e",
    "oauth": {}
};


export default awsmobile;
