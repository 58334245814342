import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import {
    Navbar,
    NavbarBrand,
    NavbarNav,
    NavItem,
    NavLink,
    NavbarToggler,
    Collapse,
    Mask,
    Row,
    Col,
    Button,
    View,
    Container,
    FormInline,
    Fa
} from "mdbreact";
import "./HomePage.css";
import Slogan from "./Slogan";

class HomePage extends React.Component {


    render() {
        return (
          <div id="apppage">
            <Router/>
            <View/>
            <Slogan/>
          </div>
        );
    }

}

export default HomePage;
