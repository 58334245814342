import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "./HomePage";
import Schedule from "./Schedule";
import AboutBodyCode from "./AboutBodyCode";
import Testimonials from "./Testimonials";
import Login from "./login/Login";
import AuthenticatedSchedule from './AuthenticatedSchedule';
import ContactUs from './ContactUs';
import Marma from './Marma';

export default class Routes extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={HomePage}/>
                <Route path="/aboutbodycode" component={AboutBodyCode}/>
                <Route path="/testimonials" component={Testimonials} />
                <Route path="/login" component={Login} />
                <Route path="/schedule" component={Schedule}/>
                <Route path="/contactus" component={ContactUs}/>
                <Route path="/marma" component={Marma}/>
            </Switch>
        )
    }
}

