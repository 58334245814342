import { I18n } from 'aws-amplify';
import { Translations } from '@aws-amplify/ui-components';

const authScreenLabels = {
  en: {
    [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: `An email with a confirmation code has been sent to your email account, please enter below:`,
  },
};

I18n.putVocabularies(authScreenLabels);
