import { withAuthenticator } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';
import './AuthenticatedSchedule.css';
import Schedule from './Schedule';

const signUpConfig = {
  hideAllDefaults: false,
  signUpFields: [
    {
      label: 'First Name',
      key: 'given_name',
      required: true,
      custom: false,
      displayOrder: 1
    },
    {
      label: 'Last Name',
      key: 'family_name',
      required: true,
      custom: false,
      displayOrder: 2
    },
    {
      label: 'Address',
      key: 'address',
      required: true,
      type: 'address',
      custom: false,
      displayOrder: 5,
    },
    {
      label: 'Date Of Birth',
      key: 'birthdate',
      required: true,
      type: 'date',
      custom: false,
      displayOrder: 4,
    }
]
}

export default withAuthenticator(Schedule,
  { usernameAttributes: 'email',
                   signUpConfig,
                   includeGreetings: true
                 });