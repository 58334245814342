import React from "react";
import EmotionCodeCert from "./assets/CECP-JZ.png";
import BodyCodeCert from "./assets/CBCP-JZ.png";
import NDCert from "./assets/ND-JZ.png";
import "./AboutBodyCode.css";

const AboutBodyCode = () => <div>
  <div className="aboutme">
    <p className="center-word">The Mind-Body Energy Healing</p>
    <p className="center-word-small">The Ultimate Health, Wealth & Happiness Solution</p>
    <p className="center-word">Healing your Body and Heart</p>
    <p className="center-word-small">Jenny Zhang - Board Certified Doctor of Naturopathy, Certified Marma Therapist, Certified Emotion Code, Body Code Practitioner</p>
    <p className="bodycode-intro">Everything in this universe is made of pure, vibrating energy.
      Mind-Body Energy Healing is all about finding the underlying causes of disease and caring for the human body in the healthiest and most complete way.</p>
    <p>My job is to communicate with your subconscious mind through energy connecting and muscle testing, to identify and correct imbalances that cause all kinds of physical and emotional problems for people, to release
      trapped emotions (emotional blockages) and correct imbalanced energy pathways in the body known as Chakras and meridians.</p>
    <p>By removing these underlying causes of disease we are able to re-establish a state of balance in the body, leading to the ideal conditions for the body to heal itself and
      stay healthy.</p>
    <p>
    Mind-Body Energy Healing can help you to:
      <ul>
        <li>Restore your health</li>
        <li>Avoid serious illness</li>
        <li>Reduce or eliminate pain</li>
        <li>De-stress your life</li>
        <li>Build healthier relationships</li>
        <li>Help you to find a deeper sense of life purpose</li>
        <li>Bring contentment and happiness to your heart</li>
        <li>Help you to create more success and abundance</li>
      </ul>
    </p>
    <div className="images">
      <img className="bodycode-cert" src={BodyCodeCert}/>
      <img className="nd-cert" src={NDCert}/>
      <img className="emotioncode-cert" src={EmotionCodeCert}/>
    </div>
  </div>
</div>;

export default AboutBodyCode;