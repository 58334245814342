import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import {Collapse, Container, Navbar, NavbarNav, NavbarToggler, NavItem, NavLink, Footer} from "mdbreact";
import Header from "./Header";

class App extends Component {
    render() {
        return (
            <Router>
                <div>
                    <Header />
                    <main>
                        <Routes />
                    </main>
                    <Footer style={{color: 'white'}}>
                        <p className="footer-copyright mb-0 py-3 text-center">
                            &copy; {new Date().getFullYear()} Copyright:{"Beauty Life LLC"}
                            <a href="https://www.beautifullifejz.com"> beautifullifejz.com </a>
                        </p>
                    </Footer>
                </div>
            </Router>
        );

    }
}

export default App;
