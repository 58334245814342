import React from "react";
import {MDBBtn, MDBIcon, MDBRow, MDBCol} from "mdbreact"
import "./ContactUs.css";

const ContactUs = () => (
  <div className="contactus">
        <p style={{color:'white'}}>
           If you have any questions, please contact{' '}
          <a href="mailto: beautifullifejz@gmail.com">
            beautifullifejz@gmail.com
          </a>
        </p>
  </div>);

export default ContactUs;