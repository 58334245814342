import React, {useState, useEffect} from "react";
import "./Testimonials.css";
import { DiscussionEmbed } from 'disqus-react';
import {disqusShortName} from "./Config";
import postAsGuestGif from "./assets/post-as-guest.gif";

const Testimonials = () => {
  const disqusConfig = {
    url: 'http://www.beautifullifejz.com/testimonials',
    identifier: '1',
    title: 'my comments',
  };

  return (
    <div className="article">
      <div>Below is a gif showing how to post as guest.</div>
      <img src={postAsGuestGif} />
      <br/>
      <DiscussionEmbed shortname={disqusShortName} config={disqusConfig} />
    </div>)
};


export default Testimonials;