import React from 'react';
import Iframe from 'react-iframe';
import './Schedule.css';
import './login/customize-auth';

class Schedule extends React.Component {
  render() {
    return (
      <div>
          <div className="appointment">
            <p>
              Please use the scheduling sections below to make appointments.
            </p>
            <p>
              If you have any questions, please contact{' '}
              <a href="mailto: beautifullifejz@gmail.com">
                beautifullifejz@gmail.com
              </a>
            </p>
          </div>
          <Iframe
            url="https://app.acuityscheduling.com/schedule.php?owner=16714197"
            width="100%"
            height="800px"
            target="_parent"
            marginLeft="300px"
            frameBorder={0}
          />
      </div>
    );
  }
}

export default Schedule;
